/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={5}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          {/* <Grid xs={12} lg={12}> */}
          <Grid container justifyContent="flex-start">
            <Grid xs={12} md={6}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="workspace_premium"
                  title="Fully certified"
                  description="Trust our certified and experienced practitioners in accordance to Allied Health Profession Act 2016 (Act 774)"
                />
              </MKBox>
            </Grid>
            <Grid xs={12} md={6}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="rocket_launch"
                  title="Report and certificate, done!"
                  description="Get the report and certificate in no time, flying straight to your email and your customer portal, ready to be downloaded in less than 24 hours"
                />
              </MKBox>
            </Grid>
            <Grid xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
                  icon="compass_calibration"
                  title="Calibrated tools"
                  description="Our up-to-date tools are calibrated at the certified SSDLs"
                />
              </MKBox>
            </Grid>
            <Grid xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
                  icon="credit_card"
                  title="Easy booking, and payment too "
                  description="Using our built-in payment gateway, you can book the appointments while managing your subscriptions in our customer portal"
                />
              </MKBox>
            </Grid>
          </Grid>
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              source=""
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
