/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();
function DefaultFooter({ color }) {
  // const { copyright } = content;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3} justifyContent="start">
          {/* <Grid item xs={12} md={3} sx={{ ml: 0, mb: 0 }}>
            <MKBox display="flex" flexDirection="column" justifyContent="start">
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="15px" mr={2} />
              </Link>
              {/* <MKTypography variant="h6">{brand.name}</MKTypography> 
            </MKBox>
            {/*  <MKBox display="flex" alignItems="center" mt={3}>
               {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}  
            </MKBox> 
          </Grid> 
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))} */}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography color={color} variant="button" fontWeight="regular">
              &copy; {date}, Fastlane Dynamic Resources Consultancy Sdn Bhd
              {/* <MKTypography
        component="a"
        href="https://www.linkedin.com/in/aimi-firdhaus-shafie/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Aimi Firdhaus Shafie
      </MKTypography>
      . All rights reserved. */}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// DefaultFooter.defaultProps = {
//   color: "inherit",
// };

// // Typechecking props for the DefaultFooter
// DefaultFooter.propTypes = {
//   color: PropTypes.oneOfType([PropTypes.string]), // color: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
// };

export default DefaultFooter;
