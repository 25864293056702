// OnlineStatusSnackbar.js
import { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

function OnlineStatusSnackbar() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
      setShowSnackbar(true);
    }

    function handleOffline() {
      setIsOnline(false);
      setShowSnackbar(false);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <Snackbar
        open={!isOnline}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        maxwidth="xs"
      >
        <Alert severity="error">
          You&apos;re offline. Please check your internet connectivity.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success">You&apos;re back online</Alert>
      </Snackbar>
    </>
  );
}

export default OnlineStatusSnackbar;
