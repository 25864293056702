/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import malaysiaCoatofArms from "assets/images/logo6.svg";
import logoMara from "assets/images/logoMara.svg";
// import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
// import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nuclearMalaysia from "assets/images/nuclearMalaysia.svg";
// import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
// import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
// import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <MKBox justifyContent="center">
          <Grid
            container
            xs={12}
            lg={12}
            justifyContent="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            {/* <MKTypography variant="h2">Trusted by over</MKTypography> */}
            {/* <MKBox
              display="flex"
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="center"
              alignItems="center"
            >
              <MKTypography variant="h2" color="info" textGradient mb={0}>
                This site is under construction
              </MKTypography>
              <MKTypography sx={{ ml: { xs: "0", lg: 3 } }} variant="h2">
                🚧
              </MKTypography>
            </MKBox> */}
            <MKTypography variant="body1" color="text" mb={2}>
              Meet our strategic partners
            </MKTypography>
          </Grid>
          {/* <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Nick Willever"
              date="1 day ago"
              review="This is an excellent product, the documentation is excellent and helped me get things done more efficiently."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Shailesh Kushwaha"
              date="1 week ago"
              review="I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Samuel Kamuli"
              date="3 weeks ago"
              review="Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!."
              rating={5}
            />
          </Grid>
        </Grid> */}
          {/* <Divider sx={{ my: 6, bgcolor: "white.main" }} /> */}
          <Grid container spacing={3} justifyContent="space-evenly">
            <Grid display="flex" justifyContent="center" xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={malaysiaCoatofArms}
                alt="Malaysia"
                width="100%"
                opacity={1}
              />
            </Grid>
            <Grid display="flex" justifyContent="center" xs={6} md={4} lg={2}>
              <MKBox component="img" src={logoMara} alt="Mara" width="70%" opacity={1} />
            </Grid>
            <Grid display="flex" justifyContent="center" xs={6} md={4} lg={2}>
              <MKBox component="img" src={nuclearMalaysia} alt="Nasa" width="100%" opacity={1} />
            </Grid>
            {/* <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />
          </Grid> */}
            {/* <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={digitalOceanLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid> */}
          </Grid>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Information;
