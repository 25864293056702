/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// import Icon from "@mui/material/Icon";
// import pekelilingBg from "assets/images/pekeliling.avif";
// import MuatTurun from "pages/LandingPages/MuatTurun";
// import aktaBg from "assets/images/judge-gavel-table.avif";
// import borangBg from "assets/images/borang.avif";
// import garisPanduanBg from "assets/images/garisPanduanBg.avif";
// import checklistBg from "assets/images/checklistBg.avif";
import KelasH from "pages/LandingPages/Kelas H";
import backgroundKelasH from "assets/images/backgroundKelasH.jpg";
// import perlesenanBg from "assets/images/perlesenanBg.jpg";
// import penguatkuasaanBg from "assets/images/penguatkuasaanBg.jpg";
// import fizikBg from "assets/images/fizikBg.jpg";
// import latihanBg from "assets/images/latihanBg.jpg";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

const routes = [
  {
    name: "Solutions",
    icon: <PrecisionManufacturingIcon />,
    collapse: [
      {
        name: "Radiology QC",
        route: "/solutions/radiologyQC",
        component: <KelasH bgImage={backgroundKelasH} unit="Radiology QC" key="Radiology QC" />,
      },
      // {
      //   name: "Software Development",
      //   route: "/expertise/software-development",
      //   component: (
      //     <KelasH
      //       bgImage={pengurusanBkrpBg}
      //       unit="Software Development"
      //       key="Software Development"
      //     />
      //   ),
      // },
      // {
      //   name: "Perlesenan & Pendaftaran",
      //   route: "/pentadbiran/perlesenan-pendaftaran",
      //   component: (
      //     <Pentadbiran
      //       bgImage={perlesenanBg}
      //       unit="Perlesenan & Pendaftaran"
      //       key="Perlesenan & Pendaftaran"
      //     />
      //   ),
      // },
      // {
      //   name: "Penguatkuasaan & Pendakwaan",
      //   route: "/pentadbiran/penguatkuasaan-pendakwaan",
      //   component: (
      //     <Pentadbiran
      //       bgImage={penguatkuasaanBg}
      //       unit="Penguatkuasaan & Pendakwaan"
      //       key="Penguatkuasaan & Pendakwaan"
      //     />
      //   ),
      // },
      // {
      //   name: "Khidmat Fizik Perubatan",
      //   route: "/pentadbiran/khidmat-fizik-perubatan",
      //   component: (
      //     <Pentadbiran
      //       bgImage={fizikBg}
      //       unit="Khidmat Fizik Perubatan"
      //       key="Khidmat Fizik Perubatan"
      //     />
      //   ),
      // },
      // {
      //   name: "Latihan & Kerjasama Inter-Agensi",
      //   route: "/pentadbiran/latihan-kerjasama-inter-agensi",
      //   component: (
      //     <Pentadbiran
      //       bgImage={latihanBg}
      //       unit="Latihan & Kerjasama Inter-Agensi"
      //       key="Latihan & Kerjasama Inter-Agensi"
      //     />
      //   ),
      // },
    ],
  },
  // {
  //   name: "Muat turun",
  //   icon: <Icon>download</Icon>,
  //   collapse: [
  //     {
  //       name: "Borang",
  //       route: "/muat-turun/borang",
  //       component: <MuatTurun name="Borang" key="Borang" bgImage={borangBg} />,
  //     },
  //     {
  //       name: "Akta, Peraturan & Polisi",
  //       route: "/muat-turun/akta-peraturan-polisi",
  //       component: (
  //         <MuatTurun
  //           name="Akta, Peraturan & Polisi"
  //           key="Akta, Peraturan & Polisi"
  //           bgImage={aktaBg}
  //         />
  //       ),
  //     },
  //     {
  //       name: "Pekeliling",
  //       route: "/muat-turun/pekeliling",
  //       component: <MuatTurun name="Pekeliling" key="Pekeliling" bgImage={pekelilingBg} />,
  //     },
  //     {
  //       name: "Garis Panduan",
  //       route: "/muat-turun/garis-panduan",
  //       component: <MuatTurun name="Garis Panduan" key="Garis Panduan" bgImage={garisPanduanBg} />,
  //     },
  //     {
  //       name: "Senarai Semak Permohonan",
  //       route: "/muat-turun/senarai-semak-permohonan",
  //       component: (
  //         <MuatTurun
  //           name="Senarai Semak Permohonan"
  //           key="Senarai Semak Permohonan"
  //           bgImage={checklistBg}
  //         />
  //       ),
  //     },
  //   ],
  // },
  // {
  //   name: "Direktori",
  //   icon: <Icon>call</Icon>,
  //   collapse: [
  //     {
  //       name: "Telefon",
  //     },
  //   ],
  // },
];

export default routes;
